<template>
    <div class="al-content">
        <div class="content-top">
            <div class="content-top clearfix">
                <h1 class="al-title ">Hamper Item PDF generate</h1>
            </div>
        </div>

        <div class="row ">

            <div class="col-md-12">
                <div class="panel">
                    <div class="panel-body">
                        <div>
                            <button class="btn btn-default dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                {{mapZones[ZoneId].Name}}
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li class="dropdown-item"
                                    v-for="item in mapZones" :key="item.Id"
                                    @click="doViewByFilter(item.Id,FilterProductTypeId)">
                                    <a href="javascript:;">{{item.Name}}</a>
                                </li>
                            </ul>

                            <button class="btn btn-default dropdown-toggle" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                                {{FilterProductTypeId==0?'== All Types ==':mapProductTypes[FilterProductTypeId].Description}}
                            </button>

                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li class="dropdown-item"
                                    v-for="item in mapProductTypes" :key="item.Id"
                                    @click="doViewByFilter(ZoneId,item.Id)">
                                    <a href="javascript:;">{{item.Id!=0?item.Description:'== All Types =='}}</a>
                                </li>

                            </ul>

                            <button class="btn btn-default" type="button" @click="$router.push('/HamperItem')">
                                Back
                            </button>
                            <button class="btn btn-danger" type="button" @click="showSelectPDF">
                                PDF Generate
                            </button>
                        </div>
                        <div class="mt-3">
                            <table class="table table-hover table-bordered" id="datatable">
                                <thead>
                                    <tr>

                                        <th> </th>
                                        <th>Name</th>
                                        <th>PDF</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in dataByFilter" :key="item.Code" :style="{'background-color':item.Stock<=0?'#ff000021 !important':'inherit'}">

                                        <td>
                                            <img :src="item.HamperImage" style="max-height:100px;max-width:100px" v-if="item.HamperImage!=''" />
                                        </td>
                                        <td>
                                            {{item.Name}} ({{item.Code}})
                                            <div>Created by <b style="font-weight:bold;color:red">{{item.CreatedBy}}</b> at {{formatDate(item.Created) }}</div>
                                            <div>Updated by <b style="font-weight:bold;color:red">{{item.UpdatedBy}}</b> at {{formatDate(item.Updated) }}</div>

                                        </td>
                                        <td>
                                            <div class="text-center" v-if="item.Pdf!=null&&item.Pdf!=''">
                                                <a href="javascript:void(0);" @click="downloadResource(item)">
                                                    <img :src="require(`@/assets/img/app/${getFileIcon(getFileExt(item.Pdf))}`)" style="width:100px;" />
                                                </a>
                                                <br />
                                                <a href="javascript:void(0);"
                                                   @click="SelectFileUpload(item)"
                                                   class="editable-click"
                                                   @id="item.Code+'upload'"
                                                   title="Click to Upload">
                                                    Change
                                                </a>
                                            </div>
                                            <a href="javascript:void(0);"
                                               @click="SelectFileUpload(item)"
                                               class="editable-click"
                                               @id="item.Code+'upload'"
                                               title="Click to Upload" v-if="item.Pdf==null||item.Pdf==''">
                                                No PDF file
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

        </div>




        <MDBModal id="editPDFGenerateModal"
                  tabindex="-1"
                  staticBackdrop
                  labelledby="exampleModalLabel"
                  v-model="showPDFGenerate"
                  size="lg">
            <MDBModalHeader>
                <MDBModalTitle> Choose item to merge PDF</MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>



                <MDBRow class="mt-3">
                    <MDBCol md="12" class="text-center">
                        <a href="javascript:;" @click="showItemsToSelect" title="add" class="ml-6">
                            <img :src="require(`@/assets/img/app/Add.png`)" />
                        </a>
                        <a style="margin-left:10px" href="javascript:;" @click="clearAllItem()" title="Clear All" class="ml-6">
                            <img style="height:48px;" :src="require(`@/assets/img/app/delete.png`)" />
                        </a>
                    </MDBCol>
                    <MDBCol v-for="item in ItemSelected" style="" :key="item.Id" md="4" class="text-center mt-3">
                        <img :src="item.HamperImage==''?require(`@/assets/img/app/no-image-vertical.jpg`):item.HamperImage" style="cursor: pointer;max-height:100px; max-width: 100px" /><br />
                        <div>{{item.Code}} - {{item.Name}}</div>
                        <div v-show="!checkFullAcl('User/IsGuest')">{{formatMoney(item.Price)}} đ</div>
                        <div>
                            <a href="javascript:;" @click="removeSelectedItem(item)" title="remove Item" class="ml-6">
                                <img :src="require(`@/assets/img/app/delete.png`)" />
                            </a>
                        </div>
                    </MDBCol>

                </MDBRow>

            </MDBModalBody>
            <MDBModalFooter>
                <button type="submit" :class="'btn btn-danger'+(ItemSelected.length>1?'':'btn-disabled')" @click="DoMergePDF">
                    Merge PDF
                </button>
                <button type="button" class="btn btn-dark" @click="showPDFGenerate=false">
                    Cancel
                </button>

            </MDBModalFooter>
        </MDBModal>


        <MDBModal id="ItemSelectModal"
                  tabindex="-1"
                  staticBackdrop
                  labelledby="exampleModalLabel"
                  v-model="showItemsSelect"
                  size="lg">
            <MDBModalHeader>
                <MDBModalTitle> </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
                <MDBRow>
                    <MDBInput label="Search"
                              id="SearchCode"
                              v-model="SearchCode" />
                </MDBRow>
                <MDBRow>
                    <MDBCol class="text-center mt-3" v-for="item in dataBoxesAvailable" @click="selectMergeItem(item)" :style="{'cursor':'pointer'}" v-show="(SearchCode==''||item.Name.toLowerCase().indexOf(SearchCode.toLowerCase())>-1||(item.Code&&item.Code.toLowerCase().indexOf(SearchCode.toLowerCase())>-1))">
                        <img :src="item.HamperImage==''?require(`@/assets/img/app/no-image-vertical.jpg`):item.HamperImage" style="max-height:100px; max-width: 100px" /><br />
                        <div>{{item.Code}} - {{item.Name}}</div>
                        <div v-show="!checkFullAcl('User/IsGuest')">{{formatMoney(item.Price)}} đ</div>
                    </MDBCol>
                </MDBRow>
            </MDBModalBody>

        </MDBModal>

    </div>
</template>
<style>
    .dataTables_wrapper .dataTables_paginate .paginate_button {
        color: white !important;
    }

    .editable-click, a.editable-click {
        border-bottom: none;
    }
</style>
<script>
    //https://therichpost.com/vue-3-datatable-with-export-buttons-print-csv-copy-with-dynamic-data/
    //Datatable Modules
    import "datatables.net-dt/js/dataTables.dataTables"
    import "datatables.net-dt/css/jquery.dataTables.min.css"
    //import "datatables.net-buttons/js/dataTables.buttons.js"
    //import "datatables.net-buttons/js/buttons.colVis.js"
    //import "datatables.net-buttons/js/buttons.flash.js"
    //import "datatables.net-buttons/js/buttons.html5.js"
    //import "datatables.net-buttons/js/buttons.print.js"
    import $ from 'jquery';
    import 'datatables.net-responsive'


    import {
        //MDBBtn,
        MDBModal,
        MDBModalBody,
        MDBModalHeader,
        MDBModalTitle,
        MDBModalFooter,
        MDBCol, MDBRow,
        MDBInput,
        MDBCheckbox,
        MDBTooltip
    } from 'mdb-vue-ui-kit';


    import c from '@/mylib/common'
    import { mapState, mapActions } from 'vuex'
    /*import { Swappable, Plugins } from '@shopify/draggable';*/


    export default {
        name: `ProductPDFMerge`,
        components: {
            MDBModal,
            MDBModalHeader,
            MDBModalTitle,
            MDBModalBody,
            MDBModalFooter,
            MDBCol, MDBRow,
            MDBInput,
            MDBCheckbox,
            MDBTooltip
        },
        data: function () {
            return {
                editTitle: '',
                editData: {},
                data: [],
                dataByFilter: [],
                ZoneId: 0,
                editZoneId: 0,
                FilterProductTypeId: 0,

                dataByFilter: {},
                mapZones: { 0: { 'Id': 0, 'Name': '-- All Regions --' } },

                FileUpload: null,
                ProductTypes: [],

                mapProductTypes: {},
                dataTable: null,
                showProductDetail: false,
                SearchCode: '',

                ToolTip: '',
                allowedType: ".doc,.docx,.xls,.xlsx,.ppt,.pptx,.pdf,.txt",
                mapFileType: { "xlsx": "Microsof Excel", "xls": "Microsof Excel", "doc": "Microsof Word", "docx": "Microsof Word", "pdf": "PDF", "ppt": "Microsof PowerPoint", "pptx": "Microsof PowerPoint", "txt": "Text Document", "URL": "Link", "jpg": "Image", "png": "Image", "jpeg": "Image" },
                mapFileIcon: { "xlsx": "excel.png", "xls": "excel.png", "doc": "word.png", "docx": "word.png", "pdf": "pdf.png", "ppt": "ppt.png", "pptx": "ppt.png", "txt": "notepad.png", "URL": "link.png", "jpg": "images.png", "png": "images.png", "jpeg": "images.png" },
                //=========== POPUP
                showItemsSelect: false,
                showPDFGenerate: false,
                dataBoxesAvailable: [],
                ItemSelected: [],
            }
        },

        computed: mapState({
            isCheckedLogin: state => state.view.isCheckedLogin,
        }),
        methods: {
            ...mapActions({
                'loading': 'view/setLoading',
                'showModal': 'view/showModal',
                'showToast': 'view/showToast',
                'setGroups': 'user/setGroups',

                'call': 'api/call'
            }),
            checkAcl(action) {
                return this.$store.state.session.acl.includes(this.$options.name + "/" + action);
            },
            checkFullAcl(router) {
                return this.$store.state.session.acl.includes(router);
            },
            start() {
                this.getAll();

            },
            formatDate(dstr, notime) {
                let d = new Date(dstr);
                if (d == "Invalid Date") return "";
                return c.formatDate(d, notime);
            },
            formatMoney(money, symbol, decCount) {
                symbol = symbol || '';
                decCount = decCount || 0;

                const rs = c.formatMoney(money, symbol, decCount);
                return rs;
            },

            showSelectPDF() {

                //sorting
                this.ItemSelected.sort((a, b) => b.Price - a.Price);
                this.showPDFGenerate = true;
            },
            showItemsToSelect() {

                this.dataBoxesAvailable = [];
                let objs = {};
                let datas = this.dataByFilter;

                objs = Object.keys(datas);
                for (let i = 0; i < objs.length; i++) {
                    let item = datas[objs[i]];
                    if (item.Pdf != null && item.Pdf != '' && !this.ItemSelected.includes(item))
                        this.dataBoxesAvailable.push(item);
                }

                //sorting
                this.dataBoxesAvailable.sort((a, b) => b.Price - a.Price);

                this.showItemsSelect = true;
            },
            selectMergeItem(selectedItem) {

                this.ItemSelected.push(selectedItem);
                this.showItemsSelect = false;

            },
            removeSelectedItem(selectedItem) {
                this.ItemSelected = _.remove(this.ItemSelected, function (n) {
                    return n == selectedItem.Code;
                });
            },
            async DoMergePDF() {
                if (this.ItemSelected.length < 2) return;
                let mergeIds = '';
                for (let i = 0; i < this.ItemSelected.length; i++) {
                    mergeIds += ',' + this.ItemSelected[i].Id;
                }

                this.loading(true);
                const rs = await this.call({ Action: 'HamperItem/MergePDF', Object: { Ids: mergeIds } })

                this.error = rs.Error;

                if (rs.Error != "") {
                    //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                    this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });
                } else {
                    c.SaveFile(rs.Data, "application/octet-stream", 'HamperItemPDF' + mergeIds.replaceAll(',', '_') + '.pdf');
                }
                this.loading(false);
            },
            clearAllItem() {
                this.ItemSelected = [];
            },


            async getAll() {
                this.loading(true);

                const rs = await this.call({ Action: 'HamperItem/View', Object: { ZoneId: this.ZoneId } })

                this.error = rs.Error;
                if (rs.Error != "") {
                    //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                    this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });
                    this.loading(false);
                    //no permission
                    if (rs.Error.includes("Permission Denied")) {
                        window.history.back();
                    }

                } else {
                    this.data = [];
                    Object.keys(rs.Data.items).forEach(id => {
                        this.data.push(rs.Data.items[id]);
                        
                    });
                    this.mapZones = { 0: { 'Id': 0, 'Name': '-- All Region --' } };
                    for (let i = 0; i < rs.Data.Zones.length; i++) {
                        this.mapZones[rs.Data.Zones[i].Id] = rs.Data.Zones[i];
                    }
                    this.ProductTypes = rs.Data.ProductTypes;
                    Object.keys(this.ProductTypes).forEach(i => {
                        let item = this.ProductTypes[i];
                        this.mapProductTypes[item.Id] = item;
                    });
                    this.doViewByFilter(this.ZoneId, this.FilterProductTypeId, true);

                }
            },
            doTable() {
                const _this = this;

                _this.dataTable = $('#datatable').DataTable({
                    responsive: true,

                });
                this.loading(false);
            },
            getFileExt(filename) {
                return filename.split('.').pop();
            },
            getFileName(filestring) {
                return filestring.split('/').pop();
            },
            getFileIcon(fileext) {

                return this.mapFileIcon[fileext] ? this.mapFileIcon[fileext] : "3d_file.png";
            },
            getFileType(fileext) {
                return this.mapFileType[fileext] ? this.mapFileType[fileext] : "Unsupport File";
            },
            doViewByFilter(zoneId, typeId, isFirstLoad) {
                if (isFirstLoad == undefined) isFirstLoad = false;
                if (this.dataTable != null) {
                    this.dataTable.destroy();
                }

                this.ZoneId = zoneId;
                this.FilterProductTypeId = typeId;
                console.log(this.FilterProductTypeId);
                this.dataByFilter = {};
                for (let i = 0; i < this.data.length; i++) {
                    if ((this.data[i].ZoneId == zoneId || zoneId == 0) && (this.data[i].ProductTypeId == typeId || typeId == 0))
                        this.dataByFilter[this.data[i].Id] = this.data[i];
                }
                setTimeout(this.doTable, isFirstLoad ? 300 : 200);
            },

            selectEditZone(zoneId) {
                this.editZoneId = zoneId;
            },
            SelectFileUpload(item) {
                var el = window._protected_reference = document.createElement("INPUT");
                el.type = "file";
                el.accept = this.allowedType;
                //el.accept = "image/*";
                //el.multiple = "multiple"; // remove to have a single file selection

                // (cancel will not trigger 'change')
                var _this = this;
                _this.FileUpload = [];
                el.addEventListener('change', function () {
                    // access el.files[] to do something with it (test its length!)
                    _this.FileUpload.push(el.files[0]);
                    $('#' + item.Code + 'upload').innerHTML = 'uploading...';
                    _this.doUpload(item);
                });

                el.click();
            },
            async doUpload(item) {
                this.showUpload = false;
                this.loading(true);
                const rs = await this.call({ Action: 'HamperItem/SavePDF', Object: { Id: item.Id }, Files: this.FileUpload });
                this.loading(false);
                this.error = rs.Error;
                if (rs.Error != "") {
                    //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                    this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });
                    if (item.Pdf == null || item.Pdf == '')
                        $('#' + item.Code + 'upload').innerHTML = 'No PDF file';
                } else {

                    this.showToast({
                        isShow: true, title: '', content: item.Name + `'s PDF are uploaded.`, type: 'success'
                    });
                    item.Pdf = rs.Data.Pdf;
                }
            },
            async downloadResource(item) {


                if (!item || item.Pdf == null || item.Pdf == '') {
                    this.showToast({
                        isShow: true, title: '', content: `File not found!`, type: 'error'
                    });
                    return;
                }
                this.loading(true);
                const rs = await this.call({ Action: 'HamperItem/DownloadPDF', Object: { Id: item.Id } })

                this.error = rs.Error;

                if (rs.Error != "") {
                    //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                    this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });
                } else {
                    c.SaveFile(rs.Data, "application/octet-stream", this.getFileName(item.Pdf));
                }
                this.loading(false);
            },
        },

        created() {

        },
        mounted() {
            //if already login then start,
            //else will watch isCheckedLogin to catch its change
            //to call start function
            if (this.isCheckedLogin) this.start();



        },
        watch: {
            //catch status from not login to logged in
            //to call start function.
            //Apply for reload page issue
            isCheckedLogin(newVal) {
                if (newVal) {
                    this.start();
                }
            },

        }
    };
</script>
